import {React, useState} from 'react';
// import 'App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import WatchTVMiniGame from '../watch-tv-minigame';
import PetCatMinigame from '../pet-cat-minigame';
import SleepMinigame from '../sleep-minigame';
import RewardMinigame from '../reward-minigame';
import ProjectItem from '../project-item';
import sdp1 from "../../images/projects/sdp_1.png";
import sdp2 from "../../images/projects/sdp_2.png";
import sdp3 from "../../images/projects/sdp_3.png";
import sdp4 from "../../images/projects/sdp_4.png";
import sdp5 from "../../images/projects/sdp_5.png";
import caq1 from "../../images/projects/caq_website_1.png";
import caq2 from "../../images/projects/caq_website_3.png";
import caq3 from "../../images/projects/caq_website_4.png";
import mq1 from "../../images/projects/mq_1.png";
import mq2 from "../../images/projects/mq_2.png";
import mq3 from "../../images/projects/mq_3.png";
import mq4 from "../../images/projects/mq_4.png";
import mq5 from "../../images/projects/mq_5.png";

import mr1 from "../../images/projects/mr_1.png";
import mr2 from "../../images/projects/mr_2.png";
import mr3 from "../../images/projects/mr_3.png";
import mr4 from "../../images/projects/mr_4.png";

import hg1 from "../../images/hg/hg_1.PNG";
import hg2 from "../../images/hg/hg_2.PNG";
import hg3 from "../../images/hg/hg_3.PNG";
import hgWidget from "../../images/hg/hg_widget.jpeg";
import hgWatch from "../../images/hg/hg_watch.png";

import hgBoatVid from "../../images/hg/hg_boat.mp4";
import hgGearsVid from "../../images/hg/hg_gears.mp4";
import hgIslandVid from "../../images/hg/hg_island_hud.mp4";
import hgOculusVid from "../../images/hg/hg_oculus.mp4";

const HomePage = () => {

  const [coin, setCoin] = useState(0);
	const [isDarkMode, setIsDarkMode] = useState(false);

	function updateCoin(value) {
		let newAmount = coin + value;

		if (newAmount < 0) {
			setCoin(0);
		} else {
			setCoin(newAmount);
		}
	}

	function onLampToggle() {	
		setIsDarkMode(!isDarkMode);
	}

	function isChristmas() {
		let todayDate = new Date();
		let todayYear = new Date().getFullYear();
		let startDate = new Date(todayYear + "-12-01");
		let endDate = new Date((todayYear) + "-12-30");
		return todayDate >= startDate && todayDate < endDate;
	}

    return (
      <div className={'App' + (isDarkMode ? ' darkMode' : '')}>
        <div className='landing'>
          <div className='landing__center'>
            <div className='landing__headerContainer'>
              <div className='landing__headerTop'>Parham Taher</div>
              <div className='landing__lineDivider'></div>
              <div className='landing__headerBottom'>Software Engineer</div>
            </div>
            <div className='landing__bioContainer'>
              <div className={'landing__bioIcon' + (isChristmas() ? " landing__bioIcon--christmas" : "")}></div>
              <div className='landing__bioText'>I'm an experienced software engineer and game developer. 
              I focus on gameplay, interactions, and animations, with a strong familiarity 
              of the mobile platform. I love games. My goal is to create meaningful experiences 
              and push the boundaries of what's already out there.</div>
            </div>
            <div className='landing__games'>
              <WatchTVMiniGame updateCoin={updateCoin} />
              <PetCatMinigame updateCoin={updateCoin} />
              <SleepMinigame updateCoin={updateCoin} onLampClicked={onLampToggle}/>
            </div>
          </div>
        </div>
        <div className='content'>
          <div className='coin'>
            <div className='coin__icon'></div>
            <div className='coin__qty'>{coin}</div>
          </div>
          
          <div className='infoSection infoSection--experience'>
            <div className='infoSection__header'>
              <div className='infoSection__header__icon'></div>
              <div className='infoSection__header__text'>Experience</div>
            </div>
            <div className='infoSection__body'>
              <div className='infoSection__body__title'>HitGrab Games</div>
              <ul className='infoSection__ul'>
                <li>
                  <div className='infoSection__body__subtitle'>Jan 2020-Present: <span>Senior Mobile Game Developer - MouseHunt</span></div>
                  <ul className='infoSection__ul__inner'>
                    <li>Working in a full-stack environment focusing on the mobile experience. Mainly responsible for front-end interface development of the mobile client (gameplay, UI/UX, animations, API routing) using Cordova.</li>
                    <li>Implementing/integrating native functionality (in-app purchases, push notifications, oAuth, etc.) and maintaining the mobile framework/lifecycle.</li>
                    <li>Deploying mobile apps to production.</li>
                    <li>Creating documentation for onboarding and mentoring junior developers.</li>
                    <li>Involved in game balancing, design, and ideation.</li>
                    <li>Developed the MouseHunt Apple Watch App and iOS widget using Swift and SwiftUI.</li>
                    <li>Led the front-end development and release of MouseHunt’s biggest expansion (The Floating Islands) through collaboration with artists, designers and other developers.</li>
                    <li>Integrated Babel for our tech stack, allowing developers to use ES6 syntax.</li>
                    <li>Currently working on the next version of MouseHunt made with Unity/C#.</li>
                  </ul>
                </li>
                <li>
                  <div className='infoSection__body__subtitle'>Jan 2019-2020: <span>Game Developer - MouseHunt</span></div>
                  <ul className='infoSection__ul__inner'>
                    <li>Worked on all aspects of game development for MouseHunt using HTML/JavaScript/CSS, REST API, PHP, and MySQL.</li>
                    <li>Created brand new interfaces for the game, including the news and profile views.</li>
                    <li>Improved responsiveness of the entire app by removing the third-party scrolling library in favor of native HTML scrollers.</li>
                    <li>Implemented pinch-to-zoom navigation for the travel map.</li>
                  </ul>
                </li>
              </ul>
              <div className='experienceMediaTitle'>MouseHunt Showcase</div>
              <div className='experienceMediaContainer'>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Prologue Pond Front-end</div>
                  <video className='experienceMediaContainer__video' width="260" height="470" autoPlay loop muted>
                    <source src={hgBoatVid} type="video/mp4" />
                  </video>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Pull to Refresh</div>
                  <video className='experienceMediaContainer__video' width="260" height="470" autoPlay loop muted>
                    <source src={hgGearsVid} type="video/mp4" />
                  </video>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Floating Islands Front-end</div>
                  <video className='experienceMediaContainer__video' width="260" height="470" autoPlay loop muted>
                    <source src={hgOculusVid} type="video/mp4" />
                  </video>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Floating Islands Front-end</div>
                  <video className='experienceMediaContainer__video' width="260" height="470" autoPlay loop muted>
                    <source src={hgIslandVid} type="video/mp4" />
                  </video>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Community Maps Search/Sort/Filter</div>
                  <img className='experienceMediaContainer__image' src={hg1}/>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Player Profile Front-end</div>
                  <img className='experienceMediaContainer__image' src={hg2}/>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Foreword Farm Front-end</div>
                  <img className='experienceMediaContainer__image' src={hg3}/>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>Apple Watch App</div>
                  <img className='experienceMediaContainer__image' src={hgWatch}/>
                </div>
                <div className='experienceMediaContainer__imageContainer'>
                  <div className='experienceMediaContainer__title'>iOS Widget</div>
                  <img className='experienceMediaContainer__image' src={hgWidget}/>
                </div>
              </div>
              <div className='infoSection__body__title'>UofT School of Continuing Studies</div>
              <ul className='infoSection__ul'>
                <li>
                  <div className='infoSection__body__subtitle'>2018-2019: Assistant Instructor - Full Stack Developer Bootcamp</div>
                  <ul className='infoSection__ul__inner'>
                    <li>Helped aspiring software developers to break into the software industry through the University of Toronto School of Continuing Studies bootcamp program.</li>
                  </ul>
                </li>
              </ul>
              <div className='infoSection__body__container'>
                <div className='infoSection__body__title'>Scorpiox Technologies</div>
                <ul className='infoSection__ul'>
                  <li>
                    <div className='infoSection__body__subtitle'>June 2018-July 2018 (2 months): Freelancer</div>
                    <ul className='infoSection__ul__inner'>
                      <li>Created a simulation of a drone air matrix system using Unity and C#.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className='infoSection infoSection--education'>
            <div className='infoSection__header'>
              <div className='infoSection__header__icon'></div>
              <div className='infoSection__header__text'>Education</div>
            </div>
            <div className='infoSection__body'>
              <div className='infoSection__body__container'>
                <div className='infoSection__body__title'>University of Toronto</div>
                <ul className='infoSection__ul'>
                  <li>
                    <div className='infoSection__body__subtitle'>2013-2018: Honors Bachelor of Science</div>
                    <ul className='infoSection__ul__inner'>
                      <li>Computer Science Specialist and Statistics Minor.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className='infoSectionProjects'>
            <div className='infoSectionProjects__header'>
              <div className='infoSectionProjects__header__icon'></div>
              <div className='infoSectionProjects__header__text'>Projects</div>
            </div>
            <div className='infoSectionProjects__body'>
              <ProjectItem
                images={[mr1, mr2, mr3, mr4]}
                name="MicroRacers"
                description="Micro Racers is a spectator friendly multiplayer racing game where players bet on AI-driven cars. Inspired by marbles racing and horse racing. Written in Unity C#"
                link="https://knowbettergames.itch.io/micro-racers"
              />

              <ProjectItem
                images={[sdp1, sdp2, sdp3, sdp4, sdp5]}
                name="Super Drinking Party"
                description="A drinking game based on a stack of interactive cards with built-in mini games. Written in Unity/C#. Google AdMob integration. Live on Google Play."
                link="https://play.google.com/store/apps/details?id=com.knowbettergames.superdrinkingparty"
              />

              <ProjectItem
                images={[mq1, mq2, mq3, mq4, mq5]}
                name="Mining Quest"
                description="A progression based Mining RPG inspired by the mining life skill of MMORPG games. Written in Unity/C#."
                link= "/miningquest"
              />

              <ProjectItem
                images={[caq1, caq2, caq3]}
                name="caqsoccer.com"
                description="Web app for a soccer Academy to allow the coaching staff to add latest news, update training schedules, etc. Written using React, Node, and MySQL."
                link="https://caqsoccer.com/"
              />
            </div>
          </div>

          <div className='infoSection infoSection--certificates'>
            <div className='infoSection__header'>
              <div className='infoSection__header__icon'></div>
              <div className='infoSection__header__text'>Certificates</div>
            </div>
            <div className='infoSection__body'>
              <div className='infoSection__body__container'>
                <ul className='infoSection__ul'>
                  <li>
                    <div className='infoSection__body__subtitle'>2018: AWS Cloud Practitioner License 61C6Z9DKBFF4QF3V</div>
                  </li>
                  <li>
                    <div className='infoSection__body__subtitle'>2018: Unity Certified Programmer</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='infoSection infoSection--other'>
            <div className='infoSection__header'>
              <div className='infoSection__header__icon'></div>
              <div className='infoSection__header__text'>Other</div>
            </div>
            <div className='infoSection__body'>
              <div className='infoSection__body__container'>
                <ul className='infoSection__ul'>
                  <li>
                    <div className='infoSection__body__subtitle'>2014-2016: Semi-Pro Soccer Player League1 Ontario.</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div className='footer'>
          <div className='footer__copyright'>Parham Taher Copyright 2023 ©</div>
          <RewardMinigame updateCoin={updateCoin} coinAmount={coin}/>
        </div>
        <div className='landing__socialContainer'>
            <div className='landing__socialIcon'>
              <a href="https://github.com/ParhamTaher" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub}/>
              </a>
            </div>						
          <div className='landing__socialIcon'>
            <a href="https://ca.linkedin.com/in/parhamtaher" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faLinkedin}/>
            </a>
          </div>
          <div className='landing__socialIcon'>
            <a href="https://www.youtube.com/watch?v=IRnvds_OlAk" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube}/>
            </a>
          </div>
        </div>
      </div>

    );
};

export default HomePage;