import { React, useState } from 'react'
import CoinPopup from './coin-popup';
import '../css/WatchTVMinigame.css';

function WatchTVMiniGame(props) {

  const [channel, setChannel] = useState(7);
  const [isStaticShow, setIsStaticShow] = useState(false);
  const [isAdChannel, setIsAdChannel] = useState(true);
  const [coinGained, setCoinGained] = useState(0);

  const adChannels = [7, 8, 9, 10, 11];

  function onSwitchChannelClicked() {

    if (isStaticShow) {
      return;
    }

    setCoinGained(0);

    let randChannel = Math.floor(Math.random() * 12);
    while (randChannel === channel) {
      randChannel = Math.floor(Math.random() * 12);
    }

    setIsStaticShow(true);

    setTimeout(() => {
      setChannel(randChannel);
      if (!adChannels.includes(randChannel)) {
        setIsAdChannel(false);
        const coinReward = Math.floor(Math.random() * 10) + 1;
        setCoinGained(coinReward);
        props.updateCoin(coinReward);
      } else {
        setIsAdChannel(true);
      }
      setIsStaticShow(false);
    }, 250);
  }

  let channelStyle = {
    backgroundPositionX: (channel/11) * 100 + "%"
  }
  return (
    <div className="minigameBox minigameBox--watchTV">
      <div className={isAdChannel ? 'minigameBox__parhamCouch minigameBox__parhamCouch--ad' : 'minigameBox__parhamCouch'}></div>
      <div className='minigameBox__tv'>
        <div className='minigameBox__tv__image'></div>
        <div className='minigameBox__tv__channel' style={channelStyle}></div>
        <div className={isStaticShow ? 'minigameBox__tv__static minigameBox__tv__static--active' : 'minigameBox__tv__static'}></div>
      </div>
      <div role='button' className='minigameBox--watchTV__tvButton' onClick={() => onSwitchChannelClicked("left")}>></div>
      {coinGained > 0 && <CoinPopup val={coinGained} />}
      {/* <div className='minigameBox__caption'>I love sitcoms, ads not so much.</div> */}
    </div>
  )
}

export default WatchTVMiniGame
