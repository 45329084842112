import React, { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const MiningQuestPage = () => {

    const { unityProvider } = useUnityContext({
        loaderUrl: "mq.loader.js",
        dataUrl: "mq.data.unityweb",
        frameworkUrl: "mq.framework.js.unityweb",
        codeUrl: "mq.wasm.unityweb",
      });

      // We'll use a state to store the device pixel ratio.
      const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

      useEffect(
        function () {
          // A function which will update the device pixel ratio of the Unity
          // Application to match the device pixel ratio of the browser.
          const updateDevicePixelRatio = function () {
            setDevicePixelRatio(window.devicePixelRatio);
          };
          // A media matcher which watches for changes in the device pixel ratio.
          const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${devicePixelRatio}dppx)`
          );
          // Adding an event listener to the media matcher which will update the
          // device pixel ratio of the Unity Application when the device pixel
          // ratio changes.
          mediaMatcher.addEventListener("change", updateDevicePixelRatio);
          return function () {
            // Removing the event listener when the component unmounts.
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
          };
        },
        [devicePixelRatio]
      );

      let isMobile = window.innerWidth < 700;

    return (
      <div className="miningQuestContainer">
        <Unity
          unityProvider={unityProvider} style={{ width: isMobile ? 337 : 540, height: isMobile ? 600 : 960, display: "block", margin: "0 auto" }}
          devicePixelRatio={devicePixelRatio}
        />

        <div className="miningQuestText">You may need to zoom out on your browser to see the entire game view. You can use "CTRL/CMD" +  "-"</div>
      </div>
    );
};

export default MiningQuestPage;