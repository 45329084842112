import { React, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons';

function ProjectItem(props) {

    const [slideIndex, setSlideIndex] = useState(0);
    
    function onImageControllerLeftClicked() {
		setSlideIndex(Math.max(0, slideIndex - 1));
	}

	function onImageControllerRightClicked() {
		setSlideIndex(Math.min(slideIndex + 1, props.images.length - 1));
	}
    
    return (
        <div className='infoSectionProjects__projectContainer'>	
            <div className='infoSectionProjects__projectContainer__images_container'>
                <div className='infoSectionProjects__projectContainer__images' style={{transform: "translateX(" + slideIndex * -290 + "px)"}}>
                    {props.images.map((image, i) => {
                        return <img key={i} src={image} />
                    })}
                </div>
                <div className='imageController left' onClick={onImageControllerLeftClicked}></div>
                <div className='imageController right' onClick={onImageControllerRightClicked}></div>
            </div>
            
            <div className='infoSectionProjects__projectContainer__title'>{props.name}</div>
            <div className='infoSectionProjects__projectContainer__desc'>
                <div className='infoSectionProjects__projectContainer__desc__inner'>
                    {props.description}
                </div>
                <a href={props.link} target="_blank" rel="noreferrer">
                    <div className='infoSectionProjects__projectContainer__link'><FontAwesomeIcon icon={faLink}/></div>
                </a>
            </div>
        </div>
    )
}

export default ProjectItem