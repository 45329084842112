import { React, useState, useEffect } from 'react'
import CoinPopup from './coin-popup';
import '../css/PetCatMiniGame.css'

function PetCatMinigame(props) {

    const [isPetting, setIsPetting] = useState(false);
    const [intervalID, setIntervalID] = useState(null);
    const [catState, setCatState] = useState("happy");
    const [coinGained, setCoinGained] = useState(0);
    const [showCoinGained, setShowCoinGained] = useState(false);
    const [petDuration, setPetDuration] = useState(0);
    const [tTime, setTTime] = useState(Math.floor(Math.random() * 10) + 3);
    const [eTime, setETime] = useState(Math.floor(Math.random() * 3) + 1);
    

    useEffect(() => {

        if (!isPetting) {
            return;
        }

        let coinReward = 0;
        if (petDuration >= tTime) {
            setCatState("end");
            coinReward = -30;
        } else if (petDuration >= tTime - eTime) {
            setCatState("furious");
            coinReward = Math.floor(Math.random() * 10) + petDuration^2;
        } else if (petDuration > tTime/2) {
            setCatState("angry");
            coinReward = Math.floor(Math.random() * 4) + petDuration;
        } else {
            coinReward = Math.floor(Math.random() * 4) + petDuration;
        }
        
        setCoinGained(coinReward);
        setShowCoinGained(true);
        props.updateCoin(coinReward);

    }, [petDuration, tTime, eTime]);


    function startPetCat(e) {

        // Prevent right mouse clicks
        if (e.button === 2){
            return;
        }

        setCoinGained(0);
        setShowCoinGained(false);
        
        setIntervalID(setInterval(() => {
            setShowCoinGained(false);
            setPetDuration((currPetDuration) => currPetDuration + 1);
        }, 1000));

        setIsPetting(true);
    }

    function stopPetCat() {
        clearInterval(intervalID);
        setIntervalID(null);
        setIsPetting(false);
        setCatState("happy");
        setTTime(Math.floor(Math.random() * 15) + 3);
        setETime(Math.floor(Math.random() * 4) + 1);
        setPetDuration(0);
    }

    let catCSS = "minigameBox__cat";
    if (isPetting) {
        catCSS += " minigameBox__cat--petting"
    }
    if (catState === "angry") {
        catCSS += " minigameBox__cat--angry"
    } else if (catState === "furious") {
        catCSS += " minigameBox__cat--furious"
    } else if (catState === "end") {
        catCSS += " minigameBox__cat--end";
    }

  return (
    <div className='minigameBox minigameBox--petCat'>
        <div className={'minigameBox--petCat__message ' + (isPetting ? "disabled" : "")}>Pet me!</div>
        <div className={catCSS} onPointerDown={(e) => {startPetCat(e)}} onPointerUp={() => {stopPetCat()}} onPointerLeave={() => {stopPetCat()}}></div>
        {showCoinGained && <CoinPopup val={coinGained} />}
    </div>
  )
}

export default PetCatMinigame