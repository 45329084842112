import React from 'react'

function CoinPopup(props) {
  return (
    <div className='coinPopup'>
        <div className='coinPopup__icon'></div>
        <div className='coinPopup__val'>{(props.val > 0 ? "+" : "") + props.val}</div>
    </div>
  )
}

export default CoinPopup