import { React, useState, useEffect } from 'react'
import CoinPopup from './coin-popup';
import '../css/SleepMiniGame.css'

function SleepMinigame(props) {
	const [isLampOff, setIsLampOff] = useState(false);
	const [coinGained, setCoinGained] = useState(0);
    const [showCoinGained, setShowCoinGained] = useState(false);

	useEffect(() => {
		setShowCoinGained(false);
		if (isLampOff) {
			props.updateCoin(1);
			setCoinGained(1);
        	setShowCoinGained(true);
		}
	}, [isLampOff])

	function toggleLamp() {
		setIsLampOff(!isLampOff);
		props.onLampClicked();
	}

	return (
		<div className='minigameBox minigameBox--sleep'>
			<div className={'minigameBox--sleep__bed' + (isLampOff ? ' off' : '')}></div>
			<div className={'minigameBox--sleep__lightSwitch' + (isLampOff ? ' off' : '')} onClick={() => {toggleLamp()}}>
				<div className='minigameBox--sleep__lightSwitch__inner'>
					<div className='minigameBox--sleep__lightSwitch__switch'></div>
				</div>
			</div>
			{showCoinGained && <CoinPopup val={coinGained} />}
		</div>
	)
}

export default SleepMinigame