import { React, useState } from 'react'
import '../css/RewardMinigame.css'

function RewardMinigame(props) {
	
	const [rewardIndex, setRewardIndex] = useState(0);
	const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
	const [didInsertCoin, setDidInsertCoin] = useState(false);
	const [showRewardResult, setShowRewardResult] = useState(false);

    const rewardTexts = ["You are awesome!", "Toss a coin to your developer.", "Slow down you crazy child.", "One who thinks they can and one who thinks they can't, are both correct.", "🐥", "🥚", "It's a game about nothing!", "What you seek is seeking you.", "Pet cat pet cat pet cat pet cat.", "42", "Wake up, Neo..."];

	function insertCoin() {
		if (props.coinAmount < 10) {
			
			return;
		}

		if (isAnimationPlaying) {
			return;
		}

		setShowRewardResult(false);
		setDidInsertCoin(true);
		
		props.updateCoin(-10);
		
		setIsAnimationPlaying(true);
	}

	function insertCoinAnimationEnd() {
		// Select quote
		setRewardIndex(Math.floor(Math.random() * rewardTexts.length));

		setShowRewardResult(true);
	}

	function paperShowAnimationEnd() {
		setIsAnimationPlaying(false);
		setDidInsertCoin(false);
	}
	
	return (
	<div className='minigameBox minigameBox--reward'>
        <div className='minigameBox--reward__slotMachine'>
            <div className='minigameBox--reward__slotMachine__top'></div>
            <div className={'minigameBox--reward__slotMachine__coin' + (didInsertCoin ? ' minigameBox--reward__slotMachine__coin--animate' : '')}
			onAnimationEnd={() => {insertCoinAnimationEnd()}}></div>
            <div className='minigameBox--reward__slotMachine__bottom'></div>
			<div className='minigameBox--reward__slotMachine__cover'></div>
			<div className={'minigameBox--reward__slotMachine__insertButton' + (isAnimationPlaying ? ' minigameBox--reward__slotMachine__insertButton--disabled' : '')} onClick={() => {insertCoin()}}>Insert Coin</div>
            <div className={'minigameBox--reward__slotMachine__paper' + (showRewardResult ? ' minigameBox--reward__slotMachine__paper--animate' : '')}
			onAnimationEnd={() => {paperShowAnimationEnd()}}>
				<div className='minigameBox--reward__slotMachine__paper__inner'>
					{rewardTexts[rewardIndex]}
				</div>
			</div>
        </div>
		<div className='minigameBox--reward__cost'>
		<div className='minigameBox--reward__cost__label'>Cost</div>
			<div className='minigameBox--reward__cost__container'>
				<div className='minigameBox--reward__cost__text'>10</div>
				<div className='minigameBox--reward__cost__icon'></div>
			</div>
		</div>
    </div>
  )
}

export default RewardMinigame