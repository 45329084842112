import { React } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import HomePage from './components/Pages/HomePage';
import MiningQuestPage from './components/Pages/MiningQuestPage';



function App() {
	
	return (
		
			<BrowserRouter>
				<Routes>
					<Route path="/miningquest" element={<MiningQuestPage />} />
					<Route path="/" element={<HomePage />} />
				</Routes>
			</BrowserRouter>

		
	);
}

export default App;
